body {
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     background-color: rgb(36, 34, 46);
     font-family: 'Roboto', sans-serif;
     margin: 0 auto;
     min-height: 100vh !important;
}

/*Se quitan las flechas de los inputs number*/
input[type='number'] {
     -webkit-appearance: textfield;
     -moz-appearance: textfield;
     appearance: textfield;
     margin: 0;
}

:root {
     --primary: rgb(255, 255, 255);
}
